<template>
  <div v-if="getPermission('product:create')">
    <v-row class="">
      <v-col lg="12" class="py-0">
        <v-tabs
          v-model="assetTab"
          background-color="transparent"
          centered
          color="cyan"
          fixed-tabs
          grow
          class="custom-tab-transparent"
          hide-slider
        >
          <!--  {
          title: "Overview",
          icon: "mdi-phone",
          key: "overview",
          permission: "product",
          status: true,
        },
        {
          title: "Add Serial No.",
          icon: "mdi-home-map-marker",
          key: "serial",
          permission: "product",
          status: true,
        },
        {
          title: "Attachment",
          icon: "mdi-attachment",
          key: "attachment",
          permission: "product",
          status: true,
        }, -->
          <v-tab class="font-size-16 font-weight-600 px-8" href="#tab-overview">
            <v-icon color="grey darken-4" left>mdi-ticket</v-icon>
            Overview
          </v-tab>
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            href="#tab-serial"
            v-if="productCreate.isSerialized"
          >
            <v-icon color="grey darken-4" left>mdi-home-map-marker</v-icon>
            Add Serial No.
          </v-tab>
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            href="#tab-attachment"
          >
            <v-icon color="grey darken-4" left>mdi-attachment</v-icon>
            Attachment
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col
        class="iv-custom-field"
        cols="3"
        md="3"
        sm="3"
        v-on:mouseleave="imageTemplate = false"
        v-on:mouseover="imageTemplate = true"
      >
        <template>
          <v-img
            :lazy-src="$defaultProfileImage"
            :src="getProfileImage ? getProfileImage : $defaultProfileImage"
            max-height="200"
            max-width="200"
            height="200px"
            width="200px"
            class="custom-grey-border custom-border-radius-50 company-image-logo margin-auto"
          >
          </v-img>
          <div class="margin-auto text-center">
            <v-btn
              class="my-4 custom-bold-button"
              outlined
              small
              v-on:click="selectImage"
              color="cyan"
              :loading="imageLoading"
              :disabled="imageLoading"
            >
              <template v-if="getProfileImage">Change Image</template>
              <template v-else>Upload Image</template>
            </v-btn>
            <div class="d-none">
              <v-file-input
                ref="uploadImage"
                v-on:change="updateProfileImage"
                :rules="imageRules"
                accept="image/png, image/jpeg, image/jpg"
              ></v-file-input>
            </div>
          </div>
        </template>
      </v-col>
      <v-col
        class="custom-content-container bt-stepper pt-0"
        cols="7"
        lg="7"
        md="7"
        sm="12"
      >
        <v-tabs-items v-model="assetTab">
          <v-tab-item :value="'tab-overview'">
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow product-create-height"
              style="max-height: 83vh; position: relative"
            >
              <v-col md="12" class="pb-0">
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Product Information
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <div class="d-flex">
                      <label
                        class="pr-2 font-weight-700 required font-size-16 width-100"
                        >Name</label
                      >
                    </div>
                    <div class="d-flex">
                      <TextField
                        id="product-name"
                        dense
                        filled
                        placeholder="Name"
                        solo
                        flat
                        counter="50"
                        v-model="productCreate.name"
                        color="cyan"
                        :maxlength="250"
                        :rules="[
                          validateRules.required(productCreate.name, 'Name'),
                        ]"
                      />
                    </div>

                    <div class="d-flex mt-5">
                      <label
                        class="pr-4 font-weight-bold font-size-16 width-100"
                      >
                        Product No.
                      </label>
                    </div>
                    <v-row>
                      <v-col cols="3" class="py-0"
                        ><label class="font-weight-700 font-size-16 width-100">
                          Prefix
                        </label></v-col
                      >
                      <v-col cols="3" class="py-0"
                        ><label class="font-weight-700 font-size-16 width-100">
                          No.
                        </label></v-col
                      >
                      <v-col cols="6  " class="py-0"
                        ><label class="font-weight-700 font-size-16 width-100">
                          Preview
                        </label></v-col
                      >
                      <v-col cols="3" class="py-0">
                        <v-text-field
                          dense
                          filled
                          placeholder="Prefix"
                          solo
                          flat
                          color="cyan"
                          class="width-100"
                          v-model.trim="productCreate.product_prefix"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" class="py-0">
                        <v-text-field
                          dense
                          filled
                          placeholder="Prefix no."
                          solo
                          flat
                          color="cyan"
                          class="width-100"
                          v-model.trim="productCreate.product_prefix_no"
                          v-on:keypress="isNumber($event)"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6" class="pb-0 pt-2">
                        <div class="font-weight-700 font-size-16">
                          {{ productCreate.product_prefix }}-{{
                            productCreate.product_prefix_no
                          }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" lg="6" class="py-0">
                        <div class="d-flex justify-space-between">
                          <label class="font-weight-700 font-size-16 width-100">
                            Brand
                          </label>
                          <v-icon small @click="BrandData">mdi-cog</v-icon>
                        </div>
                      </v-col>
                      <v-col cols="6" lg="6" class="py-0">
                        <div class="d-flex justify-space-between">
                          <label class="font-weight-700 font-size-16 width-100"
                            >Category
                          </label>
                          <v-icon small @click="manageCategoryDialog = true"
                            >mdi-cog</v-icon
                          >
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" lg="6" class="py-0">
                        <v-autocomplete
                          :items="brandList"
                          dense
                          filled
                          color="cyan"
                          item-color="cyan"
                          placeholder="Brand"
                          solo
                          flat
                          item-text="text"
                          item-value="text"
                          class="width-100"
                          v-model.trim="productCreate.brand"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Brand(s) Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="6" lg="6" class="py-0">
                        <v-autocomplete
                          dense
                          filled
                          color="cyan"
                          item-color="cyan"
                          :items="categoryList"
                          placeholder="Category"
                          solo
                          flat
                          item-text="text"
                          item-value="text"
                          class="width-100"
                          v-model.trim="productCreate.category"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Category(s) Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6" lg="6" class="py-0">
                        <label class="font-weight-700 font-size-16 width-100"
                          >Specification</label
                        >
                      </v-col>
                      <v-col cols="6" lg="6" class="py-0">
                        <label class="font-weight-700 font-size-16 width-100"
                          >Tags
                        </label>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" lg="6" class="py-0">
                        <v-text-field
                          dense
                          filled
                          placeholder="Specification"
                          solo
                          flat
                          color="cyan"
                          class="width-100"
                          :rules="[
                            validateRules.minLength(
                              productCreate.specification,
                              'Model',
                              1
                            ),
                            validateRules.maxLength(
                              productCreate.specification,
                              'Model',
                              100
                            ),
                          ]"
                          v-model.trim="productCreate.specification"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" lg="6" class="py-0">
                        <TagAutoComplete
                          type="goods"
                          v-model="productCreate.tags"
                        />
                      </v-col>
                    </v-row>
                    <label class="font-weight-700 font-size-16"
                      >Product Description</label
                    >
                    <v-textarea
                      class="pb-0"
                      hide-details
                      dense
                      filled
                      placeholder="Description"
                      solo
                      flat
                      row-height="20"
                      auto-grow
                      color="cyan"
                      v-model.trim="productCreate.description"
                      :rules="[
                        validateRules.minLength(
                          productCreate.description,
                          'Description',
                          1
                        ),
                        validateRules.maxLength(
                          productCreate.description,
                          'Description',
                          1024
                        ),
                      ]"
                      v-on:keypress="(e) => manageLimitdescr(e)"
                      v-on:paste="(e) => onPaste(e)"
                    ></v-textarea>
                    <v-col md="12" class="text-right py-0"
                      >{{
                        productCreate.description
                          ? productCreate.description.length
                          : 0
                      }}/200</v-col
                    >
                  </v-card-text>
                </v-card>
                <br />
                <v-card
                  flat
                  class="custom-grey-border remove-border-radius"
                  v-if="false"
                >
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Cost Information
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <div class="text-end">
                      <v-btn
                        depressed
                        @click="addCostSupplier()"
                        class="white--text"
                        color="cyan"
                        ><v-icon small color="white">mdi-plus</v-icon>Add</v-btn
                      >
                    </div>
                    <template
                      v-for="(row, index) in productCreate.costSupplierData"
                    >
                      <div :key="index">
                        <div class="d-flex">
                          <label
                            class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                            >Selling Price</label
                          >
                          <label
                            class="pr-5 pl-2 font-weight-700 font-size-16 width-100"
                            >Supplier Name
                          </label>
                          <label
                            class="pr-5 pl-1 font-weight-700 font-size-16 width-100"
                            >Purchase Price
                          </label>
                          <label class="pr-5 pl-5 font-weight-700"> </label>
                        </div>
                        <div class="d-flex">
                          <v-text-field
                            dense
                            filled
                            placeholder="Selling Price"
                            solo
                            flat
                            color="cyan"
                            class="pr-2 width-100"
                            v-model.trim="row.selling_price"
                          ></v-text-field>

                          <v-autocomplete
                            :items="supplierNameList"
                            dense
                            filled
                            placeholder="Supplier Name"
                            solo
                            flat
                            color="cyan"
                            class="pr-2 width-100"
                            v-model.trim="row.supplier_name"
                          ></v-autocomplete>
                          <v-text-field
                            dense
                            filled
                            placeholder="Purchase Price"
                            solo
                            flat
                            color="cyan"
                            class="pr-2 width-100"
                            v-model.trim="row.purchase_price"
                          ></v-text-field>
                          <v-btn
                            depressed
                            class="pr-2"
                            @click="removeCostSupplier(index)"
                            color="red"
                            ><v-icon color="white">mdi-delete</v-icon></v-btn
                          >
                        </div>
                      </div>
                    </template>
                  </v-card-text>
                </v-card>
                <v-card
                  v-if="false"
                  flat
                  class="custom-grey-border remove-border-radius"
                >
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Description Information
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <label class="font-weight-700 font-size-16"
                      >Description</label
                    >
                    <v-textarea
                      class="pb-0"
                      hide-details
                      dense
                      filled
                      placeholder="Description"
                      solo
                      flat
                      row-height="20"
                      auto-grow
                      color="cyan"
                      v-model.trim="productCreate.description"
                      :rules="[
                        validateRules.minLength(
                          productCreate.description,
                          'Description',
                          1
                        ),
                        validateRules.maxLength(
                          productCreate.description,
                          'Description',
                          1024
                        ),
                      ]"
                      v-on:keypress="(e) => manageLimitdescr(e)"
                      v-on:paste="(e) => onPaste(e)"
                    ></v-textarea>
                    <v-col md="12" class="text-right py-0"
                      >{{
                        productCreate.description
                          ? productCreate.description.length
                          : 0
                      }}/200</v-col
                    >
                  </v-card-text>
                </v-card>

                <!-- New -->
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Pricing Information
                    </h3>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="white--text mr-2"
                      depressed
                      color="cyan"
                      tile
                      @click="addPrice()"
                    >
                      <v-icon left>mdi-plus</v-icon>
                      Add New Price
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <v-col md="12" class="py-0 px-0">
                      <div class="middle-header-background d-flex align-center">
                        <p class="mb-0">
                          Price Information<br />
                          <span>
                            <v-icon small>mdi-progress-question</v-icon> Specify
                            the unit price, selling cost details for your
                            product</span
                          >
                        </p>
                      </div>
                    </v-col>

                    <v-col
                      md="12"
                      v-if="
                        productCreate &&
                        productCreate.priceList &&
                        productCreate.priceList.length
                      "
                      class="px-0"
                    >
                      <v-row class="mt-1">
                        <v-col class="py-0">
                          <label class="btx-label mt-2 required"
                            >Selling Price/Unit Price</label
                          >
                        </v-col>
                        <v-col class="py-0">
                          <label class="btx-label mt-2">Start Date</label>
                        </v-col>
                        <v-col class="py-0">
                          <label class="btx-label mt-2">End Date</label>
                        </v-col>
                      </v-row>
                      <template>
                        <v-row
                          v-for="(row, index) in productCreate.priceList"
                          :key="index"
                          class="mb-0"
                        >
                          <v-col cols="4" lg="4" class="py-0">
                            <v-text-field
                              :min="0"
                              oninput="if(this.value < 0) this.value = 0;"
                              type="number"
                              solo
                              flat
                              dense
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              placeholder="Selling Price/Unit Price"
                              v-on:keypress="
                                limitDecimal($event, row.selling_cost)
                              "
                              prepend-inner-icon="mdi-currency-usd"
                              v-model="row.selling_cost"
                              @keyup="discountPriceChange(index)"
                              v-on:change="discountSelling(index)"
                              :rules="[
                                validateRules.required(
                                  row.selling_cost,
                                  'Selling Price/Unit Price'
                                ),
                              ]"
                              class="mt-0"
                              hide-spin-buttons
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4" lg="4" class="py-0">
                            <DatePicker
                              class="pr-0"
                              hide-details
                              hide-top-margin
                              :disabled="
                                isStartDateDisable(index) || pageLoading
                              "
                              :loading="pageLoading"
                              placeholder="Start Date"
                              :min-date="end_min_date(row.start_date, index)"
                              v-model="row.start_date"
                              :rules="[
                                validateRules.required(
                                  row.start_date,
                                  'Start Date'
                                ),
                              ]"
                            >
                            </DatePicker>
                          </v-col>
                          <v-col
                            cols="4"
                            lg="4"
                            class="py-0 d-flex justify-space-between"
                          >
                            <div>
                              <template v-if="row.end_date">
                                <DatePicker
                                  hide-details
                                  readonly
                                  :loading="pageLoading"
                                  :disabled="true"
                                  hide-top-margin
                                  placeholder="End Date"
                                  v-model="row.end_date"
                                >
                                </DatePicker>
                              </template>
                              <em v-else class="text-muted"> no end date </em>
                            </div>
                            <v-btn
                              v-if="productCreate.priceList.length == index + 1"
                              class="white--text"
                              depressed
                              color="red darken-4"
                              tile
                              @click="removePrice(index, row)"
                              min-width="30"
                              max-width="30"
                              width="30"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </template>
                    </v-col>
                    <v-col md="12" class="py-0">
                      <hr class="mt-0" />
                    </v-col>
                    <v-row>
                      <v-col cols="4" lg="4" class="py-0">
                        <label class="font-weight-700 font-size-16 width-100"
                          >Has Custom Tax?</label
                        >
                      </v-col>
                      <v-col
                        cols="4"
                        lg="4"
                        class="py-0"
                        v-if="productCreate.custom_tax"
                      >
                        <label
                          class="font-weight-700 font-size-16 width-100 d-flex justify-space-between"
                          >Tax Name
                          <!-- <v-icon small>mdi-cog</v-icon> -->
                        </label>
                      </v-col>

                      <v-col
                        cols="4"
                        lg="4"
                        class="py-0"
                        v-if="productCreate.custom_tax"
                      >
                        <label class="font-weight-700 font-size-16 width-100"
                          >Tax Rate (%)</label
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4" lg="4" class="py-0">
                        <v-autocomplete
                          :items="customTaxList"
                          dense
                          filled
                          color="cyan"
                          item-color="cyan"
                          placeholder="Custom Tax"
                          solo
                          flat
                          item-text="text"
                          item-value="value"
                          class="width-100"
                          v-model.trim="productCreate.custom_tax"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Custom Tax(s) Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="4"
                        lg="4"
                        class="py-0"
                        v-if="productCreate.custom_tax"
                      >
                        <v-autocomplete
                          :items="taxNameList"
                          dense
                          filled
                          color="cyan"
                          item-color="cyan"
                          placeholder="Tax Name"
                          solo
                          flat
                          item-text="text"
                          item-value="value"
                          class="width-100"
                          v-model.trim="productCreate.tax_name"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Tax name(s) Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="4"
                        lg="4"
                        class="py-0"
                        v-if="productCreate.custom_tax"
                      >
                        <v-text-field
                          dense
                          filled
                          placeholder="Tax Rate "
                          solo
                          flat
                          class="width-100"
                          color="cyan"
                          v-model.trim="productCreate.tax_rate"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4" lg="4" class="py-0">
                        <label class="font-weight-700 font-size-16 width-100"
                          >Purchase date
                        </label>
                      </v-col>
                      <v-col cols="4" lg="4" class="py-0">
                        <label class="font-weight-700 font-size-16 width-100"
                          >Purchase Order
                        </label>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4" lg="4" class="py-0">
                        <DatePicker
                          class="width-100"
                          hide-details
                          hide-top-margin
                          :loading="pageLoading"
                          placeholder="Purchase date"
                          :min-date="end_min_date(productCreate.purchase_date)"
                          v-model="productCreate.purchase_date"
                        >
                        </DatePicker>
                      </v-col>
                      <v-col cols="4" lg="4" class="py-0">
                        <v-text-field
                          dense
                          filled
                          placeholder="Purchase Order"
                          solo
                          flat
                          class="width-100"
                          color="cyan"
                          v-model.trim="productCreate.purchase_order"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <br />
                <!-- New End -->
                <!-- supplier start -->
                <v-card
                  flat
                  class="custom-grey-border remove-border-radius"
                  v-if="true"
                >
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Supplier Information
                    </h3>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="white--text mr-2"
                      depressed
                      color="cyan"
                      tile
                      @click="addSupplier()"
                    >
                      <v-icon left>mdi-plus</v-icon>
                      Add Supplier
                      <!-- :disabled="isDisable" -->
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <v-col md="12" class="py-0 px-0">
                      <div class="middle-header-background d-flex align-center">
                        <p class="mb-0">
                          <span>
                            <v-icon small>mdi-progress-question</v-icon> Specify
                            the supplier, supplier code and price details for
                            your product</span
                          >
                        </p>
                      </div>
                    </v-col>

                    <v-col md="12" class="px-0">
                      <v-row
                        class="mt-1"
                        v-if="
                          productCreate &&
                          productCreate.suppliers &&
                          productCreate.suppliers.length
                        "
                      >
                        <v-col md="4" class="py-0">
                          <label
                            for="supplier fw-500"
                            class="btx-label mt-2 required"
                            >Supplier
                          </label>
                        </v-col>
                        <v-col md="4" class="py-0">
                          <label
                            for="supplier-part-number"
                            class="btx-label mt-2"
                            >Supplier Code</label
                          >
                        </v-col>
                        <v-col md="4" class="py-0">
                          <label
                            for="supplier-price"
                            class="btx-label mt-2 required"
                            >Purchasing Price</label
                          >
                        </v-col>
                      </v-row>
                      <template>
                        <v-row
                          v-for="(row, index) in productCreate.suppliers"
                          :key="index"
                          class="mb-3"
                        >
                          <v-col md="4" class="py-0">
                            <v-autocomplete
                              hide-details
                              solo
                              flat
                              dense
                              :items="
                                supplierList(
                                  productCreate.suppliers[index].supplier_id
                                )
                              "
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              id="supplier"
                              placeholder="Supplier"
                              v-model="
                                productCreate.suppliers[index].supplier_id
                              "
                              :rules="[
                                validateRules.required(
                                  productCreate.suppliers[index].supplier_id,
                                  'Supplier'
                                ),
                              ]"
                              class="mt-0"
                            >
                              <!-- @change="saveSupplierToLocal()" -->
                              <template v-slot:no-data>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-html="'No Supplier Found.'"
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col md="4" class="py-0">
                            <v-text-field
                              hide-details
                              dense
                              solo
                              flat
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              id="supplier-part-number"
                              placeholder="Supplier Code"
                              v-model="
                                productCreate.suppliers[index].part_number
                              "
                              class="mt-0"
                            ></v-text-field>
                          </v-col>
                          <v-col md="4" class="py-0">
                            <div class="d-flex">
                              <v-text-field
                                :min="0"
                                oninput="if(this.value < 0) this.value = 0;"
                                type="number"
                                hide-details
                                dense
                                solo
                                flat
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                id="supplier-price"
                                placeholder="Purchasing Price"
                                v-on:keypress="
                                  limitDecimal(
                                    $event,
                                    productCreate.suppliers[index]
                                      .supplier_price
                                  )
                                "
                                prepend-inner-icon="mdi-currency-usd"
                                v-model="
                                  productCreate.suppliers[index].supplier_price
                                "
                                :rules="[
                                  validateRules.required(
                                    productCreate.suppliers[index]
                                      .supplier_price,
                                    'Purchasing Price'
                                  ),
                                ]"
                                class="mt-0 mr-2"
                                hide-spin-buttons
                              ></v-text-field>
                              <v-btn
                                class="white--text"
                                depressed
                                color="red darken-4"
                                tile
                                @click="removeSupplier(index, row)"
                                min-width="30"
                                max-width="30"
                                width="30"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </template>
                    </v-col>
                  </v-card-text>
                </v-card>
                <!-- supplier end -->
                <v-card
                  flat
                  class="custom-grey-border remove-border-radius"
                  v-if="false"
                >
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Pricing / Quantity Information
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <div class="d-flex">
                      <label
                        class="pl-2 font-weight-700 font-size-16 width-100"
                        :class="{ 'pr-2': !updateMode }"
                        >Selling Cost / Unit Cost</label
                      >

                      <label
                        class="pl-2 font-weight-700 font-size-16 width-100"
                        :class="{ 'pr-2': !updateMode }"
                        >Threshold Price</label
                      >
                    </div>
                    <div class="d-flex">
                      <v-text-field
                        dense
                        filled
                        color="cyan"
                        placeholder="Selling Cost / Unit Cost"
                        solo
                        flat
                        class="pl-2 width-100"
                        :class="{ 'pr-2': !updateMode }"
                        type="number"
                        min="0"
                        max="9999"
                        v-on:keypress="
                          (e) => manageLimit(e, productCreate.selling_cost)
                        "
                        prepend-inner-icon="mdi-currency-usd"
                        v-model.trim="productCreate.selling_cost"
                      ></v-text-field>
                      <v-text-field
                        dense
                        filled
                        color="cyan"
                        placeholder="Threshold Price"
                        solo
                        flat
                        class="pl-2 width-100"
                        :class="{ 'pr-2': !updateMode }"
                        type="number"
                        min="0"
                        max="9999"
                        v-on:keypress="
                          (e) => manageLimit(e, productCreate.threshold_price)
                        "
                        prepend-inner-icon="mdi-currency-usd"
                        v-model.trim="productCreate.threshold_price"
                      ></v-text-field>
                    </div>

                    <div class="d-flex">
                      <label
                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                      >
                        Cost Price</label
                      >

                      <!-- <label
                  class="pl-2 font-weight-700 font-size-16 width-100"
                  :class="{ 'pr-2': !updateMode }"
                  >Quantity</label
                > -->
                    </div>
                    <div class="d-flex">
                      <v-text-field
                        dense
                        filled
                        color="cyan"
                        placeholder="Cost Price"
                        solo
                        flat
                        class="pr-2 width-50"
                        type="number"
                        min="0"
                        max="5"
                        prepend-inner-icon="mdi-currency-usd"
                        v-model.trim="productCreate.purchase_cost"
                        v-on:keypress="
                          (e) => manageLimit(e, productCreate.purchase_cost)
                        "
                      ></v-text-field>
                    </div>

                    <div v-if="false" class="d-flex w-50">
                      <label
                        class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                      >
                        Commission Rate (%)</label
                      >
                    </div>
                    <div v-if="false" class="d-flex w-50">
                      <v-text-field
                        dense
                        filled
                        color="cyan"
                        placeholder="Commission Rate (%)"
                        solo
                        flat
                        class="pr-2 width-100"
                        type="number"
                        min="0"
                        max="9999"
                        prepend-inner-icon="mdi-currency-usd"
                        v-model.trim="productCreate.commission_rate"
                        v-on:keypress="
                          limitDecimal($event, productCreate.commission_rate)
                        "
                      ></v-text-field>
                    </div>
                  </v-card-text>
                </v-card>
                <br />

                <template>
                  <div v-if="$hasWarranty && getPermission('warranty:create')">
                    <v-checkbox
                      class="mt-0 width-100"
                      color="cyan"
                      v-model.trim="warrantyActive"
                      v-on:change="checkWarrrnty($event)"
                    >
                      <template v-slot:label>
                        <label class="custom-form-label m-0 width-100"
                          >Warranty</label
                        >
                      </template>
                    </v-checkbox>
                    <template v-if="warrantyActive">
                      <div class="d-flex">
                        <label
                          class="pr-2 pl-2 font-weight-700 font-size-16 width-100"
                          >Duration</label
                        >

                        <label
                          class="pl-2 font-weight-700 font-size-16 width-100"
                          >Type</label
                        >
                      </div>
                      <!-- <template v-for="(warranty, index) in productCreate.warranty"> -->
                      <div class="d-flex">
                        <v-text-field
                          dense
                          v-mask="'###'"
                          filled
                          color="cyan"
                          placeholder="Duration"
                          solo
                          flat
                          class="pr-2 width-100"
                          v-model.trim="productCreate.warranty_duration"
                        ></v-text-field>
                        <v-select
                          :items="warrantyDurationTypes"
                          dense
                          filled
                          color="cyan"
                          hide-details
                          solo
                          flat
                          item-color="cyan"
                          class="pl-2 pr-2 width-100"
                          v-model.trim="productCreate.warranty_type"
                          placeholder="Type"
                          item-text="text"
                          item-value="value"
                        ></v-select>
                        <div v-if="false" class="pl-2 width-50">
                          <template v-if="index > 0">
                            <v-btn
                              class="mx-2"
                              color="danger"
                              dark
                              fab
                              small
                              v-on:click="removeWarranty(index, warranty.id)"
                            >
                              <v-icon dark> mdi-minus</v-icon>
                            </v-btn>
                          </template>
                          <template v-else>
                            <v-btn
                              class="mx-2"
                              color="cyan"
                              dark
                              fab
                              small
                              v-on:click="pushWarranty"
                            >
                              <v-icon dark> mdi-plus</v-icon>
                            </v-btn>
                          </template>
                        </div>
                      </div>
                    </template>
                  </div>
                </template>
                <br />
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Stock Information
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <v-row>
                      <v-col cols="12" lg="12" class="py-0 d-flex align-center">
                        <label class="font-weight-700 font-size-16 pr-15"
                          ><span>Serialized?</span>
                        </label>
                        <v-radio-group
                          v-model="productCreate.isSerialized"
                          row
                          :disabled="productId > 0"
                        >
                          <v-radio
                            on-icon="mdi-checkbox-marked"
                            off-icon="mdi-checkbox-blank-outline"
                            label="Yes"
                            :value="1"
                          ></v-radio>
                          <v-radio
                            on-icon="mdi-checkbox-marked"
                            off-icon="mdi-checkbox-blank-outline"
                            label="No"
                            :value="0"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" lg="12" class="py-0 d-flex align-center">
                        <label
                          class="font-weight-700 font-size-16 pr-7"
                          v-if="productCreate.isSerialized"
                          ><span>Track serial no.?</span>
                        </label>
                        <v-radio-group
                          v-model="productCreate.trackSerial"
                          row
                          v-if="productCreate.isSerialized"
                        >
                          <v-radio
                            on-icon="mdi-checkbox-marked"
                            off-icon="mdi-checkbox-blank-outline"
                            label="Yes"
                            :value="1"
                          ></v-radio>
                          <v-radio
                            on-icon="mdi-checkbox-marked"
                            off-icon="mdi-checkbox-blank-outline"
                            label="No"
                            :value="0"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col
                        cols="4"
                        lg="4"
                        class="py-0 d-flex justify-space-between"
                      >
                        <label class="font-weight-700 font-size-16 required"
                          ><span>UOM</span>
                        </label>
                        <v-icon small @click="UomData">mdi-cog</v-icon>
                      </v-col>
                      <v-col cols="4" lg="4" class="py-0">
                        <label class="font-weight-700 font-size-16 required"
                          >Initial Quantity</label
                        >
                      </v-col>
                      <v-col cols="4" lg="4" class="py-0">
                        <label class="font-weight-700 font-size-16 required"
                          >Re-Order Level</label
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="4" lg="4" class="py-0">
                        <v-autocomplete
                          dense
                          filled
                          color="cyan"
                          item-color="cyan"
                          :items="unitList"
                          placeholder="UOM"
                          solo
                          flat
                          item-text="text"
                          item-value="text"
                          class="pr-0 width-33"
                          v-model.trim="productCreate.unit"
                          :rules="[
                            validateRules.required(productCreate.unit, 'UOM'),
                          ]"
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No UOM Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4" lg="4" class="py-0">
                        <v-text-field
                          dense
                          filled
                          color="cyan"
                          placeholder="Initial Quantity"
                          solo
                          flat
                          :rules="
                            productCreate.product_type == 'goods'
                              ? [
                                  validateRules.required(
                                    productCreate.initial_stock,
                                    'Initial Quantity'
                                  ),
                                ]
                              : []
                          "
                          v-mask="'######'"
                          class="width-33"
                          v-model.trim="productCreate.initial_stock"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" lg="4" class="py-0">
                        <v-text-field
                          dense
                          filled
                          color="cyan"
                          placeholder="Re-Order Level"
                          solo
                          flat
                          :rules="[
                            validateRules.required(
                              productCreate.re_order,
                              'Re-Order Level'
                            ),
                          ]"
                          v-mask="'######'"
                          class="pl-0 width-33"
                          v-model.trim="productCreate.re_order"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <br />
                <v-card
                  flat
                  class="custom-grey-border remove-border-radius"
                  v-if="false"
                >
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Product Images
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <div v-if="getPermission('attachment:create')">
                      <label class="custom-form-label width-100">Images </label>
                      <div>
                        <FileTemplate
                          allowUpload
                          isMinDisplay
                          :file-type="true"
                          :attachments="productCreate.product_images"
                          v-on:file:updated="updateImages"
                          acceptFile="image/png, image/jpeg, image/jpg"
                        ></FileTemplate>
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
                <!-- Custom Form Data -->
                <template v-if="!productId">
                  <v-row>
                    <CustomForm :module="'product'" :module-id="productId" />
                  </v-row>
                  <br />
                </template>
                <!-- Custom Form Data End -->
              </v-col>
            </perfect-scrollbar>
          </v-tab-item>
          <v-tab-item :value="'tab-serial'" v-if="productCreate.isSerialized">
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow product-create-height"
              style="max-height: 79vh !important; position: relative"
            >
              <v-col md="12" class="pb-0">
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Serial no.
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <v-row>
                      <v-col xl="2" lg="2" md="3" class="py-0">
                        <label class="font-weight-700 font-size-16 pr-6"
                          ><span>Add Serial No.</span>
                        </label>
                      </v-col>
                      <v-col xl="10" lg="10" md="9" class="py-0">
                        <div>
                          <label class="font-weight-700 font-size-16"
                            ><span class="pr-5">Qty:</span
                            ><span class="text-h5 font-weight-bold"
                              ><template v-if="productCreate.initial_stock">{{
                                productCreate.initial_stock
                                  ? productCreate.initial_stock
                                  : 0
                              }}</template>
                              <template v-else>0</template> /{{
                                productCreate?.searial_numbers?.length
                              }}</span
                            >
                          </label>
                        </div>
                      </v-col>
                      <v-col xl="2" lg="2" md="3" class="py-0">
                        <label class="font-weight-700 font-size-16"
                          ><span>Auto Generated?</span>
                        </label>
                      </v-col>
                      <v-col xl="2" lg="2" md="3" class="py-0">
                        <v-radio-group
                          v-model="productCreate.isAutoGenerated"
                          row
                          :disabled="
                            productId > 0 && productCreate.isSerialized == 1
                          "
                        >
                          <v-radio
                            on-icon="mdi-checkbox-marked"
                            off-icon="mdi-checkbox-blank-outline"
                            label="Yes"
                            @change="productCreate.searial_numbers = []"
                            :value="1"
                          ></v-radio>
                          <v-radio
                            on-icon="mdi-checkbox-marked"
                            off-icon="mdi-checkbox-blank-outline"
                            label="No"
                            @change="
                              (productCreate.searial_numbers = []),
                                (productCreate.serial_prefix = null),
                                (productCreate.serial_prefix_no = null)
                            "
                            :value="0"
                            class="mr-0"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col
                        xl="8"
                        lg="8"
                        md="6"
                        class="py-0 pl-4 my-auto"
                        v-if="
                          productCreate.isAutoGenerated == 0 && productId == 0
                        "
                      >
                        <label class="font-weight-700 font-size-16 pr-10"
                          ><span>Upload your file:</span>
                        </label>
                        <v-btn
                          outlined
                          class="custom-bold-button text-white"
                          color="cyan"
                          @click="importDialog = true"
                          >Import</v-btn
                        >
                      </v-col>
                    </v-row>

                    <v-row v-if="productCreate.isAutoGenerated != 0">
                      <v-col cols="12" class="pb-0">
                        <label
                          class="pr-4 font-weight-bold font-size-16 width-100"
                        >
                          Serial no.
                        </label>
                      </v-col>
                      <v-col xl="4" lg="4" md="6" class="py-0">
                        <div class="d-flex align-center">
                          <label class="font-weight-700 font-size-16 width-100">
                            Prefix
                          </label>
                          <label class="font-weight-700 font-size-16 width-100">
                            No.
                          </label>
                        </div>
                      </v-col>
                      <v-col xl="8" lg="8" md="6" class="py-0"
                        ><label class="font-weight-700 font-size-16 width-100">
                          Preview
                        </label></v-col
                      >
                      <v-col xl="4" lg="4" md="6" class="py-0">
                        <div class="d-flex">
                          <v-text-field
                            dense
                            filled
                            placeholder="Prefix"
                            solo
                            flat
                            color="cyan"
                            class="pr-2 width-100"
                            :disabled="
                              productId > 0 && productCreate.isSerialized == 1
                            "
                            :rules="[
                              validateRules.minLength(
                                productCreate.serial_prefix,
                                'Part Number',
                                1
                              ),
                              validateRules.maxLength(
                                productCreate.serial_prefix,
                                'Part Number',
                                100
                              ),
                            ]"
                            v-model.trim="productCreate.serial_prefix"
                          >
                          </v-text-field>
                          <v-text-field
                            dense
                            filled
                            placeholder="Prefix no."
                            solo
                            flat
                            type="number"
                            color="cyan"
                            :disabled="
                              productId > 0 && productCreate.isSerialized == 1
                            "
                            class="pr-2 width-100"
                            v-on:keypress="isNumber($event)"
                            v-model.trim="productCreate.serial_prefix_no"
                          >
                          </v-text-field>
                        </div>
                      </v-col>

                      <v-col xl="8" lg="8" md="6" class="py-0">
                        <div class="d-flex align-center">
                          <div
                            class="font-weight-700 font-size-16 pr-10 text-uppercase"
                          >
                            {{ productCreate?.serial_prefix }}-{{
                              productCreate?.serial_prefix_no
                            }}
                          </div>
                          <v-btn
                            depressed
                            class="custom-bold-button text-white"
                            color="cyan"
                            v-if="
                              productId == 0 && productCreate.isSerialized == 1
                            "
                            :disabled="
                              !productCreate?.serial_prefix_no ? true : false
                            "
                            @click="generatePrefixes()"
                            >Generate</v-btn
                          >
                        </div>
                      </v-col>
                    </v-row>
                    <v-divider class="my-0"></v-divider>
                    <v-row
                      v-if="productCreate?.searial_numbers?.length > 0"
                      style="margin-bottom: 25px !important"
                    >
                      <v-col
                        lg="3"
                        md="3"
                        v-click-outside="clickOutside"
                        v-for="(row, rowindex) in productCreate.searial_numbers"
                        :key="rowindex"
                      >
                        <div class="d-flex">
                          <input
                            @input="checkCodes(row, rowindex)"
                            v-model="row.text"
                            class="mt-0 mr-0 w-100"
                            :placeholder="'Enter barcode ' + (rowindex + 1)"
                            style="
                              padding: 4px !important;
                              border: 1px solid darkgrey;
                            "
                            :disabled="
                              productId > 0 && productCreate.isSerialized == 1
                            "
                          />
                          <v-icon
                            left
                            :disabled="
                              productId > 0 && productCreate.isSerialized == 1
                            "
                            color="red"
                            @click="deleteNumber(rowindex)"
                            >mdi-close
                          </v-icon>
                        </div>
                        <div v-if="row?.error" class="red--text">
                          Serial Number Already Exists
                        </div>
                      </v-col>
                    </v-row>
                    <!--  <v-row v-else>
                      <v-col>
                        <img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
                        Uhh... There are no job type at the moment.
                      </v-col>
                    </v-row> -->
                  </v-card-text>
                </v-card>
              </v-col>
            </perfect-scrollbar>
          </v-tab-item>
          <v-tab-item :value="'tab-attachment'">
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow product-create-height"
              style="max-height: 79vh; position: relative"
            >
              <v-col md="12" class="pb-0">
                <DocumnetList
                  v-model="productCreate.files"
                  :document-type="docType"
                >
                </DocumnetList>
              </v-col>
            </perfect-scrollbar>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="2" md="2" sm="2">
        <v-card flat class="custom-grey-border remove-border-radius">
          <v-card-text class="py-6 px-0">
            <div>
              <div class="px-3">
                <v-img
                  cover
                  :src="$assetURL('media/books/product-box.png')"
                ></v-img>
                <div class="text-h6 pt-4">Lorem Ipsum text</div>
                <div class="pt-2 font-size-16 font-weight-medium">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eum
                  veritatis error ut sed. Lorem, ipsum dolor sit amet
                  consectetur adipisicing elit. Eum veritatis error ut sed.
                </div>
              </div>
            </div>
            <div class="text-center mt-5" v-if="false">
              <v-img :src="$assetURL('media/books/product-box.png')"></v-img>
              <v-btn
                depressed
                class="custom-bold-button text-white mt-8"
                color="cyan"
              >
                Add Product
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ManageBrand
      :b-dialog="manageBrandDialog"
      :brand="brandList"
      v-on:close-dialog="manageBrandDialog = false"
      v-on:get-product-brand="getOptions"
    ></ManageBrand>
    <ManageManufacturer
      :dialog="manageManufacturerDialog"
      :manufacturer="manufacturerList"
      v-on:close-dialog="manageManufacturerDialog = false"
      v-on:get-product-manufacturer="getOptions"
    >
    </ManageManufacturer>
    <ManageCategory
      product
      :c-dialog="manageCategoryDialog"
      :category="categoryList"
      v-on:close-dialog="manageCategoryDialog = false"
      v-on:get-product-category="getOptions"
    ></ManageCategory>
    <ManageUnit
      :u-dialog="manageUnitDialog"
      :unit="unitList"
      v-on:close-dialog="manageUnitDialog = false"
      v-on:get-product-unit="getOptions"
    ></ManageUnit>
    <!--  <input
        ref="refInputEl"
        type="file"
        name="file"
        hidden
        :accept="getAcceptFile()"
        @change="uploadFile"
      /> -->
    <ImportTemplate
      :dialog="importDialog"
      sample-url="public/import-samples/productbarcode.xlsx"
      api-url="serial-number/import"
      title="Import Serial Number"
      v-on:close="importDialog = false"
      v-on:response="getData"
    ></ImportTemplate>
  </div>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import WarrantyMixin from "@/core/lib/warranty/warranty.mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ManageBrand from "@/view/pages/partials/Manage-Product-Brand.vue";
import ManageManufacturer from "@/view/pages/partials/Manage-Product-Manufacturer.vue";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import ManageUnit from "@/view/pages/partials/Manage-Product-Unit.vue";
import DatePicker from "@/view/components/DatePicker";
import CustomForm from "@/view/pages/custom-field-v2/CustomForm.vue";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
import { mapGetters } from "vuex";

import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  QUERY,
  PATCH,
  DELETE,
  UPDATE_ERROR,
  CLEAR_ERROR,
  GET,
} from "@/core/services/store/request.module";
// import { find } from "lodash"; map
import { cloneDeep, filter, find } from "lodash";
// import FileUpload from "@/view/components/FileUpload";
//import FileUpload from "@/view/components/app-component/FileInput.vue";
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import DocumnetList from "@/view/pages/DocumnetList";

export default {
  name: "product-create",
  mixins: [
    CommonMixin,
    WarrantyMixin,
    ProductMixin,
    ValidationMixin,
    FileManagerMixin,
  ],
  props: {
    productId: {
      type: [Number, String],
      default: null,
    },
    barcodeData: {
      type: String,
      default: null,
    },
    updateMode: {
      type: Boolean,
      default: false,
    },
    productCreateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  components: {
    FileTemplate,
    DatePicker,
    ManageBrand,
    ManageManufacturer,
    ManageCategory,
    ManageUnit,
    //FileUpload,
    CustomForm,
    ImportTemplate,
    TagAutoComplete,
    DocumnetList,
  },
  data() {
    return {
      docType: 9,
      assetTab: "tab-overview",
      imageLoading: false,
      profile_logo: null,
      activeRowIndex: null,
      amount: 0,
      asset_image: null,
      proDuplicateId: null,
      timeoutLimit: 500,
      pageLoading: false,
      timeout: null,
      brandList: new Array(),
      categoryList: new Array(),
      manufacturerList: new Array(),
      //  supplierList: new Array(),
      importDialog: false,
      vendorList: [],
      tagsList: [],
      taxList: new Array(),
      taxExemptionList: new Array(),
      unitList: new Array(),
      barcode: null,
      manageBrandDialog: false,
      manageManufacturerDialog: false,
      manageCategoryDialog: false,
      manageUnitDialog: false,
      warrantyActive: false,
      supplierNameList: [],
      dbSerielNumbers: [],
      excelData: [],
      customTaxList: [
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "No",
          value: 0,
        },
      ],
      taxNameList: [],
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "overview",
          permission: "product",
          status: true,
        },
        {
          title: "Add Serial No.",
          icon: "mdi-home-map-marker",
          key: "serial",
          permission: "product",
          status: true,
        },
        {
          title: "Attachment",
          icon: "mdi-attachment",
          key: "attachment",
          permission: "product",
          status: true,
        },
      ],
      preNumbers: [],

      productCreate: {
        model: null,
        model_no: null,
        name: null,
        product_prefix: null,
        product_prefix_no: null,
        serial_prefix: null,
        serial_prefix_no: null,
        specification: null,
        tags: null,
        custom_tax: 1,
        tax_name: null,
        tax_rate: null,
        purchase_date: null,
        purchase_order: null,
        reference: null,
        isSerialized: 0,
        trackSerial: 1,
        isAutoGenerated: 1,
        searial_numbers: [],
        isSelect: null,
        sku: null,
        upc: null,
        ean: null,
        isbn: null,
        ref_number: null,
        item_number: null,
        serial_number: null,
        part_number: null,
        hsn_code: null,
        description: null,
        // selling_price: null,
        // supplier_name: null,
        // purchase_price: null,
        warranty_type: null,
        warranty_duration: 0,
        directory: null,
        supplier: null,
        brand: null,
        barcode: null,
        manufacturer: null,
        category: null,
        unit: null,
        tax: null,
        tax_exemption: null,
        accounting: "none",
        product_type: "goods",
        purchase_cost: null,
        selling_cost: null,
        project_price: null,
        threshold_price: null,
        /*  quantity:1, */
        commission_rate: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        taxable: false,
        sellable: true,
        confirmed: false,
        track_batch: false,
        manage_stock: true,
        reorder_level: null,
        initial_stock: null,
        re_order: null,
        charges: null,
        images: [],
        product_image: null,
        attachments: [],
        product_images: [],
        product_attachments: [],
        website_url: null,
        costSupplierData: [
          {
            id: null,
            selling_price: null,
            supplier_name: null,
            purchase_price: null,
          },
        ],
        warranty: [
          {
            id: null,
            value: null,
            field: null,
          },
        ],
        priceList: [
          {
            id: null,
            unit_price: 0,
            selling_price: 0,
            discount_price: 0,
            start_date: moment().format("YYYY-MM-DD"),
            end_date: null,
          },
        ],
        suppliers: [
          {
            id: null,
            uuid: null,
            product_id: null,
            supplier_price: null,
            part_number: null,
          },
        ],
        files: [
          {
            file: null,
            name: null,
            remark: null,
            suffix: null,
            tags: null,
            search: null,
            start_date: null,
            end_date: null,
            reminder_date: null,
          },
        ],
      },
      validFileTypes: ["xls", "xlsx"],
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  watch: {
    barcodeData(param) {
      this.barcode = param;
    },
    productCreateData: {
      deep: true,
      handler(param) {
        this.productCreate = param;
        this.productCreate.barcode = param.barcode ? param.barcode : null;
        this.$nextTick(() => {
          if (param.warranty_type && param.warranty_duration) {
            this.warrantyActive = true;
          }
        });
        if (this.$route.query.duplicate) {
          this.productCreate.barcode = param.barcode
            ? param.barcode
            : this.barcode;
        }
      },
    },

    productCreate: {
      deep: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.$emit("saveProduct", _this.productCreate);
        }, _this.timeoutLimit);
      },
    },
    "productCreate.product_type"() {
      this.getOptions();
    },
  },
  methods: {
    deleteNumber(index) {
      this.productCreate?.searial_numbers?.splice(index, 1);
    },
    getData(data) {
      this.productCreate.searial_numbers = data;
      this.preNumbers = this.lodash.cloneDeep(data);
      if (this.preNumbers?.length > 0) {
        this.hasDuplicatesAll();
      }
    },
    generatePrefixes() {
      if (
        this.productCreate?.serial_prefix_no &&
        this.productCreate?.initial_stock
      ) {
        this.productCreate.searial_numbers = [];
        for (let i = 1; i <= this.productCreate.initial_stock; i++) {
          // Check if serial_prefix is defined and not empty
          let prefix = this.productCreate?.serial_prefix
            ? `${this.productCreate.serial_prefix}-`
            : "";
          let serialNumber = `${prefix}${String(
            Number(this.productCreate.serial_prefix_no) + i - 1
          ).padStart(this.productCreate?.serial_prefix_no?.length, "0")}`;

          let newCode = {
            uuid: uuidv4(),
            text: serialNumber,
            editable: true,
            error: false,
          };

          this.productCreate.searial_numbers.push(newCode);
        }
        this.preNumbers = this.lodash.cloneDeep(
          this.productCreate.searial_numbers
        );
      }
    },

    // generatePrefixes() {
    //   if (
    //   /*   this.productCreate?.serial_prefix && */
    //     this.productCreate?.serial_prefix_no &&
    //     this.productCreate?.initial_stock
    //   ) {
    //     this.productCreate.searial_numbers = [];
    //     for (let i = 1; i <= this.productCreate.initial_stock; i++) {
    //       let newCode = {
    //         uuid: uuidv4(),
    //         text: `${this.productCreate?.serial_prefix}-${String(
    //           Number(this.productCreate.serial_prefix_no) + i - 1
    //         ).padStart(this.productCreate?.serial_prefix_no?.length, "0")}`,
    //         editable: true,
    //         error: false,
    //       };
    //       this.productCreate.searial_numbers.push(newCode);
    //     }
    //     this.preNumbers = this.lodash.cloneDeep(
    //       this.productCreate.searial_numbers
    //     );
    //   }
    //   /* this.hasDuplicatesAllDB();  */
    // },

    clickOutside() {
      this.activeRowIndex = null;
    },
    checkCodes(barcodeToCheck) {
      const preArray = this.lodash.cloneDeep(this.preNumbers);
      if (barcodeToCheck.text) {
        barcodeToCheck.error = false;
        if (this.productCreate && preArray) {
          const duplicate = find(
            preArray,
            (row) => row.text == barcodeToCheck.text
          );
          if (duplicate) {
            barcodeToCheck.error = true;
          }
        }
      }
    },
    hasDuplicatesAll() {
      const uniqueTexts = {};
      return this.productCreate.searial_numbers.map((item) => {
        if (uniqueTexts[item.text]) {
          item.error = true;
        } else {
          uniqueTexts[item.text] = true;
        }
        return item;
      });
    },
    hasDuplicatesAllDB() {
      /* this.productCreate.searial_numbers =  this.dbSerielNumbers;
      console.log(this.productCreate.searial_numbers) */
      const uniqueTexts = {};
      return this.productCreate.searial_numbers.map((item) => {
        if (uniqueTexts[item.text]) {
          item.error = true;
        } else {
          uniqueTexts[item.text] = true;
        }
        return item;
      });
    },

    onFocus(event, rowIndex) {
      this.activeRowIndex = rowIndex;

      this.$nextTick(() => {
        const ourForm = event.target;
        const ourInp = ourForm.querySelector("input");
        if (ourInp) ourInp.focus() || ourInp.select();
      });

      this.isOutside = false;
      const element = event.target;
      //console.log(element.nodeName);
      const _line_items = this.sales_detail.map((row) => {
        return { ...row, isEditable: false };
      });
      this.sales_detail = _line_items;
      this.sales_detail[rowIndex].isEditable = true;
      this.$nextTick(() => {
        const inputarea = element.querySelector("input");
        if (inputarea) {
          inputarea.focus();
          inputarea.select();
        }
      });
      if (element.nodeName == "INPUT") {
        element.select();
      }
    },

    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateProfileImage(param) {
      const _this = this;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          let data = _this.lodash.head(response);
          _this.asset_image =
            data && data.file && data.file.url ? data.file.url : null;
          _this.productCreate.images = [data.id];
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    checkWarrrnty(event) {
      if (!event) {
        this.productCreate.warranty_type = null;
        this.productCreate.warranty_duration = 0;
      } else {
        if (this.updateMode) {
          this.$emit("get:warrantyActive", true);
        }
        // this.productCreate.warranty_type = this.productCreate.warranty_type;
        // this.productCreate.warranty_duration = this.productCreate.warranty_duration;
      }
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.productCreate.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.productCreate.description = trimValue;
        e.preventDefault();
      }
    },

    manageLimitdescr(e) {
      if (
        this.productCreate.description &&
        this.productCreate.description.length > 199
      ) {
        e.preventDefault();
      }
    },
    addCostSupplier() {
      this.productCreate.costSupplierData.push({
        id: null,
        selling_price: null,
        supplier_name: null,
        purchase_price: null,
      });
    },
    removeCostSupplier(index) {
      this.productCreate.costSupplierData.splice(index, 1);
    },
    addSupplier() {
      this.productCreate.suppliers.push({
        id: null,
        supplier_id: null,
        part_number: null,
        supplier_price: null,
      });
    },
    // removeSupplier(index, data) {
    //   if (data.id) {
    //     const param = { id: data.id };
    //     RemoveSuppliers(this.uuid, param).then(() => {
    //       this.suppliers.splice(index, 1);
    //     });
    //   } else {
    //     this.suppliers.splice(index, 1);
    //   }
    // },
    removeSupplier(index, data) {
      // console.log(data, "data");

      this.productCreate.suppliers.splice(index, 1);

      const _this = this;

      _this.formLoading = true;
      //  const param = data.id;

      if (this.productId > 0) {
        _this.$store
          .dispatch(PATCH, {
            url: "product/" + this.productId + "/inventory_supplier",
            data: {
              id: data.id,
            },
          })

          .then(() => {})
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
      //const param = data.id;
      /* ApiService.PUT(`product/${param}/inventory_supplier`, )
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      }); */

      // console.log(data, "data");
      // if (data.id) {
      //  // console.log(data.id,"dataid");
      // //	const param = { id: data.id };
      // 	// RemoveSuppliers(this.uuid, param).then(() => {
      // 	// 	this.suppliers.splice(index, 1);
      // 	// });

      // } else {
      // 	this.productCreate.suppliers.splice(index, 1);
      // }
    },
    discountPriceChange(index) {
      if (
        this.productCreate.priceList[index].discount_price >
        this.productCreate.priceList[index].selling_cost
      ) {
        this.productCreate.priceList[index].discount_price =
          this.productCreate.priceList[index].selling_cost;
      }
    },
    discountSelling(index) {
      if (
        this.productCreate.priceList[index].selling_cost <
        this.productCreate.priceList[index].discount_price
      ) {
        this.productCreate.priceList[index].selling_cost =
          this.productCreate.priceList[index].discount_price;
      }
    },
    addPrice() {
      let previous_end_date = null; // moment().format("YYYY-MM-DD");
      if (
        this.productCreate &&
        this.productCreate.priceList &&
        this.productCreate.priceList.length < 1
      ) {
        previous_end_date = moment().format("YYYY-MM-DD");
      }
      this.productCreate.priceList.push({
        id: null,
        unit_price: 0,
        selling_cost: 0,
        discount_price: 0,
        start_date: previous_end_date,
        end_date: null,
      });
    },

    removePrice(index) {
      if (index >= 1) {
        this.productCreate.priceList[index - 1].end_date = null;
      }
      this.productCreate.priceList.splice(index, 1);
    },

    Categorydata() {
      this.manageCategoryDialog = true;
      this.getOptions();
    },
    BrandData() {
      this.manageBrandDialog = true;
      this.getOptions();
    },
    UomData() {
      this.manageUnitDialog = true;
      this.getOptions();
    },
    manageLimit(e, value) {
      this.limitDecimal(e, value);
      if (value && value.length > 12) {
        e.preventDefault();
      }
    },
    updateImages(param) {
      this.productCreate.images = param;
    },
    updateAttachments(param) {
      this.productCreate.attachments = param;
    },
    pushWarranty() {
      this.productCreate.warranty.push({
        id: null,
        value: null,
        field: null,
      });
    },
    removeWarranty(index, id) {
      const _this = this;
      if (id && id > 0) {
        _this.$store
          .dispatch(DELETE, {
            url: "product/" + _this.productId + "/entity",
            data: {
              type: 1,
              entities: [id],
            },
          })
          .then(() => {
            _this.productCreate.warranty.splice(index, 1);
          })
          .catch((error) => {
            _this.logError(error);
          });
      } else {
        _this.productCreate.warranty.splice(index, 1);
      }
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: { product_type: _this.productCreate.product_type },
        })
        .then((response) => {
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.brand
          ) {
            _this.brandList = response.data.brand;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.category
          ) {
            _this.categoryList = response.data.category;
            /*      _this.categoryList.push({
              text: this.productCreateData.category,
            });
            console.log(this.productCreateData,'response.data.category') */
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.manufacturer
          ) {
            _this.manufacturerList = response.data.manufacturer;
          }

          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.tax
          ) {
            _this.taxList = response.data.tax;
            if (!_this.productCreate.tax) {
              let index = _this.lodash.findIndex(
                response.data.tax,
                function (tax) {
                  return tax.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax = _this.taxList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.tax_names
          ) {
            _this.taxNameList = response.data.tax_names;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.taxExemption
          ) {
            _this.taxExemptionList = response.data.taxExemption;
            if (!_this.productCreate.tax_exemption) {
              let index = _this.lodash.findIndex(
                response.data.taxExemption,
                function (taxExemption) {
                  return taxExemption.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax_exemption =
                  _this.taxExemptionList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.unit
          ) {
            _this.unitList = response.data.unit;
            //  console.log(_this.unitList);
          }

          if (!_this.updateMode) {
            if (
              !_this.productId &&
              _this.lodash.isEmpty(response.data) === false &&
              response.data.barcode
            ) {
              _this.barcode = response.data.barcode;
              _this.productCreate.barcode = response.data.barcode;
            }

            if (_this.proDuplicateId) {
              _this.barcode = response.data.barcode;
              _this.productCreate.barcode = response.data.barcode;
            }

            /*  if (_this.lodash.isEmpty(response.data.barcode_setting) === false) {
              _this.barcodeSetting = response.data.barcode_setting;
              _this.productCreate.description =
                _this.barcodeSetting.description;
            } */

            _this.productCreate.serial_number = response.data.serial_number;
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    getAllSerialNumbersProduct() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "all-serial-number/goods",
        })
        .then((response) => {
          this.dbSerielNumbers = response?.data?.rows;
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  computed: {
    getProfileImage() {
      return this.asset_image
        ? this.asset_image
        : this.productCreate?.product_image?.file?.url;
    },
    getEndDate() {
      let endDateExists = true;
      if (this.productCreate?.priceList) {
        for (let i = 0; i < this.productCreate?.priceList.length; i++) {
          if (!this.productCreate?.priceList[i].end_date) {
            endDateExists = false;
            /*   break; */
          }
        }
      }
      return endDateExists;
    },
    ...mapGetters(["errors", "localDB", ""]),
    supplier_list() {
      return this.localDB("suppliers", []);
    },
    supplierList: () => {
      return function (id) {
        //console.log(id,'id');
        let _vendorList = cloneDeep(this.vendorList);
        // console.log(_vendorList,'_vendorList')
        //  console.log(this.productCreate.suppliers,'111')
        const newArray = filter(_vendorList, (row) => {
          if (row.value != id) {
            if (
              !find(
                this.productCreate.suppliers,
                (inner_row) => inner_row.supplier_id == row.value
              )
            ) {
              return row;
            }
          } else {
            return row;
          }
        });
        return newArray;
      };
    },

    isStartDateDisable: () => {
      return function (index) {
        if (this.productCreate.priceList.length == index + 1) {
          return false;
        }
        return true;
      };
    },
    end_min_date: () => {
      return function (date, index) {
        let _priceList = cloneDeep(this.productCreate.priceList);
        if (index > 0) {
          const min_end_date = _priceList[index - 1].start_date;
          this.productCreate.priceList[index - 1].end_date = date;
          return min_end_date;
        }
        return moment().format("YYYY-MM-DD");
      };
    },

    //   isDisable() {
    // 	const exists =  this.priceList.find((row) => {
    // 		return !row.start_date;
    // 	});
    // 	return exists ? true : false;
    // },
  },
  mounted() {
    this.$store.dispatch(SET_LOCAL_DB).then(() => {
      this.vendorList = this.$store.state.config.localDB["data"]["suppliers"]; //this.localDB("suppliers", []);
      //console.log(this.$store.state.config.localDB['data']['suppliers'],"2222");
    });

    this.getOptions();
    this.getAllSerialNumbersProduct();
  },
  created() {
    const _this = this;
    _this.proDuplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
};
</script>
